import React from 'react'
import { graphql, Link } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'

class NotFoundPage extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="404: Page Not Found" url={this.props.location.href} />
        <h1>Page Not Found</h1>
        <p>If you are here, it means that there is a link that misguded you.</p>
        <p>Feel free to let me know on my social media which one (see below 👇).</p>
        <p><Link to="/start-here">However, do not fear - you can start here once again.</Link></p>
      </Layout>
    )
  }
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
